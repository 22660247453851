#mobileapp {
  background-color: #1c1c1e;

  /* padding-bottom: 100px !important; */
}

.download-content {
  /* padding: 10px; */
  margin-top: 6rem;
  margin-left: 3rem;
}
.google {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 30px;
}
.google-img {
  width: 60%;
  margin-left: 3.5rem;
}
.mobiles {
  margin-left: 7rem;
  margin-top: 3rem;
}
.mobiles img {
  max-width: 100%;
}
/* .mobile1 {
  margin-top: 30px;
}
.mobile2 {
  margin-top: 20%;
  margin-left: -20%;
} */

@media screen and (max-width: 767px) {
  /* .google {
    padding-top: 2rem;
  } */
  #download {
    font-size: 30px;
  }
  .download-content {
    margin-left: 0%;
    margin-top: 0rem;
  }
  .mobiles {
    margin-left: 0px;
  }
  .mobile1 {
    width: 70%;
  }
  .mobile2 {
    width: 70%;
    margin-top: -120%;
    margin-left: 30%;
  }
}
