#Contact-us {
  background: black;
}

.form {
  margin: 0 auto;
  max-width: 27rem;
  padding: 0 1rem;
}

.input-field {
  width: 100%;
  height: 49px;
  background: #2c2c2e;
  border-radius: 10px;
  outline: none;
  border: none;
  padding: 1rem;
  margin: 1rem 0;
  color: white;
}

.textarea {
  width: 100%;
  height: 15rem;
  margin-top: 20px;
  background: #2c2c2e;
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 1.5rem;
  color: white;
}

.input-field::placeholder {
  color: rgba(255, 255, 255, 0.38);
}
.textarea::placeholder {
  color: rgba(255, 255, 255, 0.38);
}


.term-condition {
  color: #ba5eef;
}

#form-btn {
  margin: 2% auto;
  display: block;
  padding: 0.7rem 3rem;
}

@media screen and (min-width: 768px) {
  .form {
    max-width: 40rem;
  }
}

@media screen and (min-width: 992px) {
  .form {
    max-width: 66rem;
  }
}
