.main-container {
    position: relative;
    padding: 2rem 4rem ;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0 auto;
    background-color: black;
  }
  h1,h2,h3,h4,h5,h6{
    color:white
  }

  h2{
    
    padding-bottom: 10px;
  }
 
  .policy-section {
    margin-bottom: 20px;
  }
  
  .policy-section h4 {
    margin-bottom: 10px;
    color:white
  }
  
  .policy-section p {
    margin-bottom: 10px;
    color:white;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }


  .noLanguage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust height as needed */
    background-color: #333; /* Background color */
  }
  
  .message {
    color: white; /* Text color */
    text-align: center; /* Center align text */
    padding: 20px; /* Padding for spacing */
    max-width: 80%; /* Limit message width */
  }
  

  @media (width<=640px) {
    h2{
        padding-top:3rem
    }
  }

  @media screen and (max-width: 667px) {
    .main-container {
      padding: 0.5rem 1rem;
    }    
  }
  
  