#AboutComunity {
  background-color: black;
}

.community-img {
  max-width: 106%;
  max-height: 587px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.community-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.3rem;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  #AboutComunity {
    padding: 0 1.5rem !important;
    height: max-content;
  }
  #about-community-heading {
    font-size: 30px;
    padding-left: 1rem;
  }
  .learnmore {
    font-size: 30px;
  }
}
@media screen and (width<350px) {
  #about-community-heading {
    font-size: 30px !important;
  }
  #AboutComunity {
    padding: 0 1.5rem !important;
    height: max-content;
  }
}
