#footer {
  background: #000000 50%;
}

.empty {
  height: 5rem;
  max-width: 100%;
  background: #1c1c1e;
}
.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  gap: 1rem;
}
.footer-logo {
  width: 100px;
  height: 86px;
  margin-left: 30%;
}
.footer-heading {
  /* Heading/H5 */

  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */
  margin-left: 20%;
  color: #ffffff;
}
.footer-des {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: justify;
  letter-spacing: 0.01em;

  color: #ffffff;
}

.footer-ul {
  text-decoration: none;
  list-style: none;
  margin: 1rem 1rem;
}

.footer-link {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  /* identical to box height */
  text-decoration: none;

  text-align: justify;
  letter-spacing: 0.01em;

  /* Primary Color Purple */

  color: #ba5eef;
}

.footer-link:hover {
  cursor: pointer;
  color: white;
  text-decoration: none;
}
.legal_li{
  color: #ba5eef;

}
.legal_li:hover {
  cursor: pointer;
  color: white;
  text-decoration: none;
}
.legal_li Link{
  text-decoration: none;
}
.footer-form {
  padding: 2rem 0.3rem;
}
.footer-input {
  /* Secondary Color Black */
  color: white;
  background: #2c2c2e;
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 0.5rem;
  margin: 0.7rem 0;
  width: 100%;
}
.footer-heading2 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
  /* identical to box height */

  text-align: justify;

  color: #ffffff;
}
.form-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  /* Font Color */
  color: rgba(255, 255, 255, 0.87);
}
.footer-input::placeholder{
  color: rgba(255, 255, 255, 0.38);
}
NavLink{
  cursor: pointer;
}

.term-condition{
  cursor: pointer;

}


