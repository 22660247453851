#benifits {
  background-color: black;
  
}

.benifits-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
  text-align: center;

  color: #d9d9d9;
}
.benifit-img {
  padding: 0rem 3.5rem !important;
  
}

@media screen and (width< 750px) {
  .benifit-img {
    padding: 0rem 2rem !important;
  }
}
