#staff-main {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2c2c2e;
  /* padding: 3rem; */
  height: 100dvh;

  background: url("../../Images/bg-logo.png") no-repeat center center, #1c1c1e;
}
.staff-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  max-width: 100%;
  padding: 1.5rem;

  backdrop-filter: blur(1px);
  background-color: rgba(43, 37, 37, 0.3);
}
.staff-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* padding: 2rem; */
  gap: 1rem;
}
.staff-input {
  width: 379px;
  height: 51px;
  flex-shrink: 0;
  max-width: 100%;
  padding-left: 1rem;
  border-radius: 3px;
  border: 0.3px solid rgba(0, 0, 0, 0.3);
  background: var(--disabled, rgba(255, 255, 255, 0.38));
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.05);
}
input::placeholder {
  color: white;
  font-size: 15px;
}
.DYXVr {
  min-width: 0 !important;
  border: 2px dashed white !important;
}
.DYXVr svg > path {
  color: #1c1c1e !important;
  fill: white !important;
}
.fDoQYg > span {
  font-size: 12px;
  color: white !important;
}
@media screen and (width<=768px) {
  .heading {
    font-size: 20px !important;
  }

  input::placeholder {
    font-size: 10px;
  }
}
