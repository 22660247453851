#Beard-friends {
  background: url("../../Images/bg-logo.png") no-repeat center center, #1c1c1e;

  position: relative;
}
#beardfriendrow {
  padding: 0%;
  margin-top: 5rem;
}
#beardfriendcol {
  padding: 0%;
}
.beard-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 0rem;
}

#LP1 {
  max-width: 100%;
  margin-top: 1.5rem;
}

@media screen and (max-width: 767px) {
  #Beard-friends {
    /* padding: 0px !important; */
    height: max-content;
  }
  #beardfriendrow {
    margin-top: 1rem;
  }
  #BF-content {
    padding: 0 1.5rem !important;
  }
  #beard-friends {
    font-size: 30px;
    padding-left: 4rem;
  }
  .secondary-text {
    font-size: 15px;
    line-height: 30px;
  }
}

@media screen and (width> 1500px) {
  #Beard-friends {
    /* padding: 0 30px !important; */
    height: max-content;
  }
}
