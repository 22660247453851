* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: "Nunito";
}
.btn-1 {
  background: #ba5eef;
  padding: 10px 16px;
  /* margin-left: 5rem; */
  border-radius: 10px;
  outline: none;
  border: none;
  color: white !important;
  transition: 300ms ease-in-out;
}
.btn-1:hover {
  transform: scale(1.1);
}
.heading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 65px;
  color: rgba(255, 255, 255, 0.87);
  text-align: center;
}
.secondary-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 37px;
  text-align: justify;

  /* Medium white */

  color: #d9d9d9;
}

.learnmore {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  list-style: none;
  text-decoration: none;

  color: white;
  transition: 300ms ease-in-out;
}
.learnmore:hover {
  color: white;
  cursor: pointer;
  transform: scale(1.1);
}

/* //swiper css/ */
.swiper-btns {
  display: flex;
  justify-content: space-between;

  margin-top: 3rem;
}


@media screen and (width<=768px) {
  .heading {
    font-size: 30px !important;
  }


}
