.terms-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: black;
    color:white
  }
  
  h1 {
    font-size: 2.5em;
  
    text-align: center;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 1.5em;
  
    margin-top: 20px;
  }
  
  p {
    font-size: 1em;
  
    margin-bottom: 15px;
  }
  
  ul {
    margin-left: 20px;
    list-style-type: disc;
  }
  
  li {
    margin-bottom: 10px;
  }
  