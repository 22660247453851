.privacy-policy {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    padding: 2rem 4rem;
    background-color: black;
    
  }
  h1,h2,h3,h4,h5,h6{
    color:white
  }
  .privacy-policy h1 {
    padding-bottom: 2rem;
  }
  
 
  
  .privacy-policy ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  .privacy-policy ul li {
   color:white
  }
 
   
  
  .privacy-policy p {
    margin-bottom: 1em;
    color:white
  }
  
  .privacy-policy a {
    color: #007bff;
    text-decoration: none;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 667px) {
    .privacy-policy {
      padding: 0.5rem 1rem;
    }    
  }
  
  